<script setup lang="ts">
import { router } from './router'
import { PageName, StorageKey } from './models/enums'
import { UserTokens } from './models/login'
const spotStore = useSpotStore()
const userStore = useUserStore()
const pageUrl = window.location.href
let _window: any
_window = window
const checkForValidLogin = () => {
  const code = new URL(window.location.href).searchParams.get('code')
  const state = new URL(window.location.href).searchParams.get('state')
  console.info(`get wechat code:`, code)
  if (state) {
    const hotspot = state.split('#')[0]
    spotStore.setScanRecord(hotspot)
    console.log('hotspot from qrcode:', hotspot)
  }
  if (userStore.checkTokenIsExpired()) {
    console.info(`token expired`)
    userStore.logout()
    if (code) {
      userStore.userInfo.wechatCode = code
    }
    router.replace({ name: PageName.HOME })
  } else {
    console.info(`The token exists and has not expired`)
    userStore.setUserTokens()
    if (userStore.userInfo.userTokens.phone_number) {
      userStore.storageUserTokens()
    } else {
      router.replace({ name: PageName.BINDING_PHONE })
    }
  }
}

const createPageSpy = () => {
  const { mode } = apiConfig
  const tokens = storage.getItem(StorageKey.USER_TOKENS) as UserTokens
  new _window.PageSpy({
    project: '大師航拍',
    autoRender: mode === 'development' || mode === 'test',
    title: tokens ? tokens.username || navigator.userAgent : navigator.userAgent,
  })
}

createPageSpy()
onMounted(async () => {
  // if (IS_DEV) {
  //   console.log('模拟登录', `是否开发环境：${IS_DEV}`)
  //   const { username, password, openid, phone_number } = envConfig
  //   const forceExpired = false
  //   const tokenExpired = userStore.checkTokenIsExpired(username, forceExpired)
  //   if (tokenExpired) {
  //     const res = await userAccountLogin(username, password)
  //     console.log(res)
  //     const { access_token, expires_at } = res
  //     storage.setItem(StorageKey.ACCOUNT, {
  //       openid,
  //       access_token,
  //       expires_at,
  //       phone_number,
  //     })
  //   }
  // }
  if (pageUrl.indexOf('/memory/hotpot-detail/video') > -1) {
    return
  }
  checkForValidLogin()
  // 用于jssdk url签名参数
  storage.setItem(StorageKey.WX_CODE_URL, window.location.href)
})
</script>

<template>
  <div class="app-page">
    <RouterView></RouterView>
  </div>
</template>

<style scoped lang="scss">
.app-page {
  height: 100vh;
}

:deep(.amap-logo) {
  display: none !important;
}

:deep(.amap-copyright) {
  display: none !important;
}
</style>
