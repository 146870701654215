import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { PageName } from '@/models/enums'
import { IS_DEV } from '@/config/api'
const otherBrowerWhite = ['Wechat', 'Video']
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: PageName.MAIN },
  },
  {
    path: '/',
    name: PageName.MAIN,
    component: () => import('@/views/main/index.vue'),
    redirect: { name: PageName.HOME },
    children: [
      {
        path: '/home',
        name: PageName.HOME,
        component: () => import('@/views/home/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
      {
        path: '/memory',
        name: PageName.MEMORY,
        component: () => import('@/views/memory/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
      {
        path: '/order',
        name: PageName.ORDER,
        component: () => import('@/views/order/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
      {
        path: '/profile',
        name: PageName.PROFILE,
        component: () => import('@/views/profile/index.vue'),
        meta: {
          isAuthn: false,
        }
      },
    ]
  },
  {
    path: '/home/select-wayline/:id/:rn',
    name: PageName.SELECT_WAYLINE,
    component: () => import('@/views/home/pages/select-wayline-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/aerial-journey/:journey_id/:hotspot/:is_started/:late_time',
    name: PageName.AERIAL,
    component: () => import('@/views/aerial/index.vue'),
    meta: {
      isAuthn: true,
    }
  },
  {
    path: '/login',
    name: PageName.LOGIN,
    component: () => import('@/views/login/index.vue'),
    meta: {
      isAuthn: false,
    }
  },
  // {
  //   path: '/logout',
  //   name: PageName.LOGOUT,
  //   component: () => import('@/views/logout/index.vue'),
  //   meta: {
  //     isAuthn: true,
  //   }
  // },
  {
    path: '/binding-phone',
    name: PageName.BINDING_PHONE,
    component: () => import('@/views/login/pages/binding-phone.vue'),
    meta: {
      isAuthn: false,
    }
  },
  {
    path: '/tutorials',
    name: PageName.TUTORIALS,
    component: () => import('@/views/tutorials/index.vue'),
  },
  {
    path: '/memory/hotpot-detail/:reference_number/:order_id',
    name: PageName.HOTPOT_DETAIL,
    component: () => import('@/views/memory/pages/hotpot-detail-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/memory/hotpot-detail/video/:hotspot/:order/:token',
    name: PageName.VIDEO,
    component: () => import('@/views/memory/pages/video-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: false,
    }
  },
  {
    path: '/order/order-detail/:id',
    name: PageName.ORDER_DETAIL,
    component: () => import('@/views/order/pages/order-detail-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/notice',
    name: PageName.NOTICE,
    component: () => import('@/views/profile/pages/notice-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/notice/notice-detail/:id',
    name: PageName.NOTICE_DETAIL,
    component: () => import('@/views/profile/pages/notice-detail-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/language',
    name: PageName.LANGUAGE,
    component: () => import('@/views/profile/pages/language-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: false,
    }
  },
  {
    path: '/profile/setting',
    name: PageName.SETTING,
    component: () => import('@/views/profile/pages/setting-page.vue'),
    meta: {
      withNavbar: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/service-agreement',
    name: PageName.SERVICE_AGREEMENT,
    component: () => import('@/views/profile/pages/service-agreement-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/modify-pn',
    name: PageName.MODIFY_PN,
    component: () => import('@/views/profile/pages/modify-pn-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/privacy-policy',
    name: PageName.PRIVACY_POLICY,
    component: () => import('@/views/profile/pages/privacy-policy-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/profile/setting/about-mss',
    name: PageName.ABOUT_MSS,
    component: () => import('@/views/profile/pages/about-mss-page.vue'),
    meta: {
      withNavbar: true,
      lastPage: true,
      isAuthn: true,
    }
  },
  {
    path: '/mss-wechat',
    name: PageName.WECHAT,
    component: () => import('@/views/wechat/index.vue'),
    meta: {
      isAuthn: false,
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  const name = to.name as PageName
  const userStore = useUserStore()

  // 处理特殊页面直接放行
  if ([PageName.VIDEO, PageName.LOGOUT].includes(name)) {
    return next()
  }

  // 非微信环境跳转到微信引导页
  if (!isMobileAndWeChatBrowser() && !otherBrowerWhite.includes(name) && !IS_DEV) {
    return next({ name: PageName.WECHAT })
  }

  // 记录登录前的页面
  if (from.name && to.name === PageName.LOGIN) {
    userStore.pageNameBeforeLogin = from.name as any
  }

  // 回到首页时清空路由历史
  if (to.name === PageName.HOME) {
    userStore.routerHistoryList = []
  }

  // 处理导航栏路由历史
  const { withNavbar } = to.meta || {}
  const { lastPage } = from.meta || {}
  const { routerHistoryList } = userStore

  if (withNavbar && routerHistoryList[routerHistoryList.length - 1] !== to.name && !lastPage) {
    routerHistoryList.push(from.name)
  }

  next()
})

// 添加全局后置守卫
router.afterEach((to, from) => {

})

router.onError((error, to) => {
  console.error(`路由跳转${String(to.name)}失败`, error)
})