export default {
    tabBar: {
        masterMap: '大師地圖',
        memory: '回憶',
        profile: '我的',
        order: '訂單',
        noDataPopup: {
            title: '無此打卡點',
            content: '您可移至其他打卡點掃描二維碼',
        },
        spotNotMatch: {
            title: '訂單不適用於該打卡點',
            content: '請前往下單的打卡點掃碼使用',
        },
        success: {
            title: '掃碼成功',
            content: '您當前還沒有可使用的訂單哦~'
        },
        fail: {
            title: '掃碼失敗',
            content: '請重新嘗試掃碼或諮詢客服~'
        },
        livestreamFail: {
            title: '航拍啟動失敗',
            content: '非常抱歉，由於本次航拍遇到阻礙無法使用。訂單將會自動退款，請留意退款消息。'
        },
        notHotspotQrcode: '非有效打卡點二維碼',
        exceededUsageTime: '已超過使用時間',
    },
    homePage: {
        attractionCard: {
            loginToReservation: '登錄後預訂航拍',
            workingTime: '營業時間',
        },
        checkinCard: {
            desc: '已開始啦~ </br>請前往打卡點掃碼使用',
            buttonText: '馬上掃碼'
        },
        orderPopup: {
            desc: '有1個訂單即將開始',
            buttonText: '查看'
        },
        loginTipsBar: {
            desc: '立即登錄大師航拍體驗完整功能',
            buttonText: '去登錄'
        },
        runningJourneyTipsBar: {
            desc: '您有一個大師航線正在進行中',
            buttonText: '進入'
        },
        selectFlightPage: {
            selectFlight: '選擇大師航線',
            flightDesc: '包含: 3-5分鐘航拍，手勢互動，拍照10張，手勢互動',
            selectDate: '請選擇預約日期',
            selectTime: '請選擇使用時間',
            reserved: '已預訂',
            selected: '已選',
            soldOut: '當天售罄',
            paymentPopup: {
                desc: '請在使用時間<span style="color: #FFF; font-size: 1.4rem; font-weight: 700; margin:0 0.8rem">開始前5分鐘</span>掃碼打卡。</br>如超過開始時間5分鐘未掃碼使用視為放棄航拍。',
            },
            verifyPopup: {
                title: '活動碼驗證',
                desc: '由於活動數量有限，請聯繫現場工作人員獲取驗證碼，驗證再預約。',
                placeholder: '輸入6位活動碼',
            }
        }
    },
    memoryPage: {
        title: '打卡回憶',
        checkYourRecordAfterLogin: '登錄後查看您的打卡記錄',
        noHotpot: '還沒有打卡記錄',
        video: '視頻',
        photo: '照片',
        zhang: '張',
        photoProcessing: '照片處理中',
        photoProcessingError: '照片處理錯誤',
        photoProcessingExpired: '照片處理超時',
        unlockMore: '解鎖更多',
        hotpotDetailPage: {
            title: '打卡詳細',
            videoProcessing: '視頻處理中',
            videoError: '視頻文件出錯，可嘗試下拉刷新',
            videoFail: '視頻剪輯失敗，可嘗試聯係客服',
            photoProcessing: '照片處理中',
            photoError: '加載錯誤',
            downloadPhoto: '點擊照片預覽，長按保存~',
            pullrefresh: '下拉刷新',
            pullrefreshing: '正在刷新',
            easyRefresh: '鬆開刷新'
        },
        videoPage: {
            title: '視頻下載',
            downloading: '下載中',
            click: '點擊右上角',
            openInBrower: '在默認瀏覽器打開',
            text: '該頁面<br />點擊下載並保存視頻'
        }
    },
    orderPage: {
        checkOrderAfterLogin: '登錄後查看您的订单',
        noOrder: '當前還沒有航拍訂單',
        currentOrders: '當前訂單',
        historicalOrders: '歷史訂單',
        orderId: '訂單編號',
        paid: '已支付',
        fullRefund: '全額退款',
        partialRefund: '收取手續費{value}%，退款',
        shadow: '未支付',
        orderDetailPage: {
            title: '訂單詳情',
            goThere: '去這裡',
            paymentTime: '支付時間',
            startTime: '開始時間',
            endTime: '結束時間',
            refundDetails: '退款明細',
            paymentDetails: '支付明細',
            refundPrice: '退款金額',
            totalPrice: '總價',
            partialRefund: '本次退款需收取手續費{value}%',
            awaitingUseNote: {
                title: '待使用',
            },
            usedNote: {
                title: '已使用',
                desc: '感謝您使用，期待再次相遇'
            },
            expiredNote: {
                title: '已過期',
                desc: '已超過報到有效時限'
            },
            unfinishedNote: {
                title: '未完成',
                desc: '系統檢測到有飛行危險，已中斷'
            },
            cancelledNote: {
                title: '已取消',
                desc: '因天氣原因無法使用，已退款'
            },
            refundsProgressNote: {
                title: '退款中',
                desc: '已提交退款申請，系統核實中'
            },
            refundFailureNote: {
                title: '退款失敗',
                desc: '不符合退款條件，申請被駁回'
            },
            refundedNote: {
                title: '已退款',
                desc: '金額原路退回，請留意短信提示'
            },
            deviceErrorNote: {
                title: '未完成',
                desc: '因航拍遇到阻礙</br>支付金額將自動退款，請耐心等待'
            },
            refundApplicationPopup: {
                title: '退款申請',
                dontWanaUse: '行程有變故/不想用了',
                nonFlyable: '外部因素/天氣問題/不可飛行',
                unusedOvertime: '超時未使用',
                hazardousConditions: '使用過程中遇危險情況，未完成航拍',
                explanation: '說明原因狀況',
                detailsDesc: '請這裡填寫原因以便我們瞭解情況',
                uploadPicture: '上傳圖片',
                parentheses: '(提高審核通過率，最多4張)',
                longText: '由於無人機飛行中會出現突發性的危險情況，需要終止航拍，本次退款將原路返還到您的支付賬號上。提交申請後請留意消息通知。'
            },
            quesstionPopup: {
                title: '是否確認退款？',
                subTitle: '本次申請需要額外手續費{value}%作為商家損失費，<br />是否確定退款？',
            },
            feedbackPopup: {
                title: '提交成功',
                subTitle: '系統正在加速核實中',
                description: '審核通過後，款項將會在7-10個工作日退回',
            }
        },
        orderStatus: {
            NOTIFICATION_TYPE_UNKNOWN: '未知',
            TICKET_TYPE_NEW: '待使用',
            TICKET_TYPE_COMPLETED: '已使用',
            TICKET_TYPE_ABORTED: '已使用',
            TICKET_TYPE_CHECKED: '待使用',
            TICKET_TYPE_EXPIRED: '已過期',
            TICKET_TYPE_CANCELLED: '未完成',
            TICKET_TYPE_DRC_INIT_FAILED: '未完成',
            TICKET_TYPE_LIVE_STREAM_FAILED: '未完成',
            TICKET_TYPE_LIVE_STREAM_PUSHED: '進行中',
            TICKET_TYPE_BAD_WEATHER: '已取消',
            TICKET_TYPE_REFUND_REQUESTED: '退款中',
            TICKET_TYPE_REFUND_REJECTED: '退款失敗',
            TICKET_TYPE_REFUND_ACCEPTED: '已退款',
            TICKET_TYPE_SHADOW: '未支付',
        }
    },
    profilePage: {
        switchLanguage: '切換語言',
        clickLogin: '點擊登錄',
        service: '聯繫客服',
        notice: '系統消息',
        setting: '設置',
        servicePopup: {
            title: '微信客服',
            desc: '長按二維碼識別<br />為您提供快捷專屬的諮詢服務'
        },
        loginPopup: {
            content: '登錄後查看詳細'
        },
        noticePage: {
            title: '系統消息',
            viewDetails: '查看詳情',
            successfulBooking: '預訂成功',
            orderCanceled: '訂單已取消',
            refundRequestSubmittedSuccessfully: '退款申請提交成功',
            refundRequestFailed: '退款申請失敗',
            refundRequestApproved: '退款申請已通過',
            noNotice: '還沒有消息記錄',
            noticeDetailsPage: {
                title: '消息詳情',
            },
            notificationType: {
                NOTIFICATION_TYPE_UNKNOWN: '未知',
                NOTIFICATION_TYPE_RESERVE_SESSION_FAILED: '預約失敗',
                NOTIFICATION_TYPE_RESERVE_SESSION_SUCCESS: '預約成功',
                NOTIFICATION_TYPE_ORDER_PAYMENT_FAILED: '支付失敗',
                NOTIFICATION_TYPE_ORDER_PAYMENT_SUCCESS: '支付成功',
                NOTIFICATION_TYPE_ORDER_UPCOMING: '訂單即將開始',
                NOTIFICATION_TYPE_ORDER_USED: '訂單已使用',
                NOTIFICATION_TYPE_ORDER_ABORTED: '用戶終止訂單',
                NOTIFICATION_TYPE_ORDER_CANNCELLED: '系統終止訂單',
                NOTIFICATION_TYPE_ORDER_REFUND_REJECTED: '退款失敗',
                NOTIFICATION_TYPE_ORDER_REFUND_APPROVED: '退款成功',
                NOTIFICATION_TYPE_ORDER_REFUND_ACCEPTED: '已申請退款',
                NOTIFICATION_TYPE_ORDER_REFUND_REQUESTED: '已申請退款',
                NOTIFICATION_TYPE_ORDER_REFUND_RETURNED: '退款成功',
            }
        },
        switchLanguagePage: {
            title: '切換語言',
            switchComplete: '切換完成',
        },
        settingPage: {
            title: '設置',
            account: '賬號',
            about: '關於',
            changePhoneNumber: '更改手機號',
            serviceAgreement: '用戶協議',
            privacyPolicy: '隱私政策',
            AboutMss: '關於大師航拍',
            writeoffAccount: '註銷賬號',
            popup: {
                title: '確定註銷賬號？',
                subTitle: '註銷成功後，你講無法登錄或以任何方式使用当前账号，且後續無法恢復，請關注以下重要提醒：',
                deregisteredSuccessfully: '註銷成功',
                contenth3: '本次註銷具體包括如下信息',
                contentp1: '1.註銷賬號後以下信息將依照相關法律規定及協議約定，清空且無法恢復，包括個人信息、大師航拍賬號信息、訂單信息、航拍視頻/照片等。',
                contentp2: '2.在使用大師航拍服務中產生的交易金額，將不給予退還。',
            }
        },
        serviceAgreementPage: {
            title: '用戶協議',
        },
        privacyPolicyPage: {
            title: '隱私政策',
        },
        aboutMssPage: {
            title: '關於大師航拍',
            desc: '《旅遊景點共享無人機系統-大師航拍》：<br />是專為旅遊愛好者提供的全自動共享無人機服務。',
            slogan: '讓世界看見中國的美',

        },
        changePhoneNumberPage: {
            title: '更改手機號',
            newPhoneNumber: '請輸入新手機號碼',
            verifyCurrentPhone: '驗證當前手機並解綁',
            originalPhoneNumber: '請輸入原手機號碼',
            modifyPhoneBinding: '綁定新手機號',
            modifyPhoneBindingSuccess: '綁定新手機號成功',
            tips: '點擊下一步即爲解綁當前手機號',
            successToast: {
                title: '更改成功',
            },
            errorToast1: {
                title: '手機號已被使用',
            },
            errorToast2: {
                title: '短信服務錯誤',
            }
        }
    },
    loginPage: {
        loginByWechat: '微信一鍵登錄',
        noLogin: "暫不登錄",
        weChatAuthorizedLogin: '微信授權登錄',
        tipsText: '我已閱讀並同意',
        userAgreement: '《用戶協議》',
        privacyPolicy: '《隱私政策》',
        popup: {
            title: '協議/政策',
            content: '為了更好的保障您的合法權益，請您閱讀並同意以下協議<span style="color: #016BFF">《用戶協議》《隱私政策》</span>'
        }
    },
    bindingPhonePage: {
        tips: '首次登錄需要綁定您的手機號',
        phone: '請輸入手機號碼',
        verificationCode: '請輸入短信驗證碼',
        getCode: '獲取驗證碼',
        getCodeAgain: '重新獲取',
        bindingPhone: '綁定手機',
        phoneError: '請輸入正確位數的手機號碼',
        unbindPhoneError: '該號碼與綁定號碼不一致，請重新輸入',
        verificationCodeError: '驗證碼錯誤，請重新輸入',
        areaCode: {
            mainLand: '中國大陸',
            hk: '中國香港',
            mo: '中國澳門',
            tw: '中國台灣'
        },
        successToast: {
            title: '綁定成功',
        },
        toast1: {
            title: '手機號已被綁定',
            content: '請更換手機號再嘗試'
        },
        toast2: {
            title: '短信驗證碼錯誤',
            content: '請重新獲取驗證碼'
        }
    },
    wechatPage: {
        title: '請使用微信訪問<span style="color: #42BDFF">大師航拍</span>',
        desc1: '用微信“掃一掃”，現場掃描打卡點上的二維碼即可進入大師航拍官網。',
        desc2: '在當前頁面截圖發送到微信聊天框，長按識別二維碼添加公眾號，在公眾號進入官網。',
    },
    tutorialsPage: {
        step1: {
            title: '航拍站位',
            description: '注意觀測現場指示</br>請站在地上貼有標誌點的最佳航拍位置上等待拍攝。'
        },
        step2: {
            title: '拍照操作',
            description1: '滑動操控幹可控制攝影角度，調整畫面位置。',
            description2: '上下拖動滾軸可放大/縮小，控制畫面變焦。',
            description3: '大師角度。',
            description4: '點擊回到系統設置好的最美鏡頭位置。',
            description5: '即將拍照',
            description6: '拍照',
            description7: '點拍照後會進入3秒面延時拍照',
        },
        step3: {
            title: '實時播報',
            description: '無人機飛行中會採用語音和文字結合方式提示，</br>請留意手機音量。'
        },
        step4: {
            title: '中斷航拍',
            description1: '點擊“中斷”按鈕退出本次航拍，視為航拍結束。',
            description2: '當前拍攝記錄會保存在回憶',
            description3: '如航拍中，無人機因外部因素遇到飛行危險，系統會自動中斷結束本次航拍，可到訂單申請退款。',

        },
        tutorialsPopup: {
            title: '航拍準備開始啦~',
            subTitle: '為保證良好的航拍體驗</br>請確保已站在腳印範圍',
            buttonText: '我已在腳印範圍內',
        },
        guideline: '指引',
        yourReservation: '您的預訂航拍',
        started: '已開始',
        asaPossible: '請盡快啟動航拍',
        yourReservationStill: '您預訂的航拍還有',
        keepInMind: '開始，請了解以下註意事項'
    },
    aerialPage: {
        step1: {
            name: '起飛'
        },
        step2: {
            name: '自拍'
        },
        step3: {
            name: '航拍'
        },
        shotTips: '即將拍照',
        flightPoints: '航線節點',
        flightInitTips: '無人機啟動中',
        flightFinishPopup: {
            statusText: '您已完成本次航拍之旅',
            subTitle: '預計<span style="color: #FFF; font-size: 1.8rem; font-weight: 700; line-height: 2.6rem; margin:0 0.8rem">30分鐘後</span>圖片視頻會處理完成<br />並記錄在《回憶》中，請耐心等待~',
        },
        autoTakePhotoText: '準備進入<br />自動拍照啦~',
        stopFlightPopup: {
            title: '是否强制中斷航拍？',
            subTitle: '強制中斷航拍將視為完成本次航拍，當前拍攝<br />內容會保存到《回憶》中。',
            content: '強制停止航拍將不能申請退款'
        },
        terminateFlightPopup: {
            title: '航拍終止提示',
            subTitle: '抱歉，由於無人機飛行中遇到危險情況，系統將<br />終止本次飛行任務。',
            content: '您可到訂單詳情中申請退款'
        },
        liveStreamFailedPopup: {
            title: '無人機直播連接中斷',
            subTitle: '連接無人機直播畫面時出錯，請嘗試重新掃描二維碼。',
        },
        flightDescription: {
            default: '無人機啟動中，請耐心等待~',
            d0: '無人機正在從機場飛出',
            d1: '就位拍照',
            d2: '就位拍攝',
        },
        loadingToast: {
            photo: '已進入自拍',
            recording: '已進入航拍',
            exit: '航拍結束，正在退出',
        }
    },
    common: {
        hotpot: '打卡點',
        copy: '複製',
        copySuccess: '複製成功',
        copyFailed: '複製失敗',
        verifySuccess: '驗證成功',
        verifyFailed: '驗證失敗',
        useDate: '使用日期',
        useTime: '使用時間',
        date: '日期',
        time: '時間',
        noMore: '沒有更多啦~',
        purchaseNotes: {
            title: '購買須知',
            note1: '“使用時間”的前5分鐘即可提前使用航拍；',
            note2: '需到現場打卡點掃描二維碼使用；',
            note3: '超過“使用時間”5分鐘后，不能再使用航拍 ，且不可退改，請確認好時間後再行購買。',
        },
        refundRules: {
            title: '退款規則',
            refundRequestTimeToUse: '申請退款距使用時間',
            unusedOvertime: '超時未使用',
            otherReason: '外部因素/天氣因素/不可飛行',
            handlingFee: '手續費',
            verification: '核實通過'
        },
        termsOfUse: {
            title: '使用須知',
            howToUse: {
                title: '如何使用',
                description: '前往打卡點現場，掃描二維碼即可使用航拍。'
            },
            timeOfUse: {
                title: '使用時間',
                description: '使用時間前5分鐘到後5分鐘，例如8:15，8:10 - 8:20可使用。'
            },
            timeoutUnused: {
                title: '超時未使用',
                description: '超過使用時間5分鐘後講不可使用，視為放棄本次航拍。'
            },
        },
        puzzleVcode: {
            desc: '向右滑動完成驗證',
        },
        servicePopup: {
            title: '微信客服',
            content: '長按二維碼識別</br>為您提供快捷專屬的諮詢服務'
        },
        hour: '小時',
        minute: '分鐘',
        more: '更多',
        paymentSuccessful: '支付成功',
        sessionNotAvailable: '當前時間已被預訂',
    },
    buttonText: {
        confirm: '確定',
        cancel: '取消',
        nextStep: '下一步',
        reservation: '預訂航拍',
        viewOrder: '查看訂單',
        aerialPhotographyGuide: '航拍指引',
        agreeAndLogin: "同意並登錄",
        toLogin: '去登錄',
        reserveNow: '馬上預訂',
        downloadVideo: '下載視頻',
        gotIt: '我知道了',
        requestRefund: '申請退款',
        contactCustomerService: '聯繫客服',
        submitApplication: '提交申請',
        logout: '退出登錄',
        confirmSwitching: '確定切換',
        completionBonding: '完成綁定',
        agreeAndBind: '同意並綁定',
        login: '登錄',
        notApply: '不申請了',
        identifyAndApply: '確定並申請',
        thinkAbout: '我再想想',
        definitiveCancellation: '確定註銷',
        startupAerial: '啟動航拍',
        viewMemory: '查看回憶',
        breakAerial: '中斷航拍',
        continueAerial: '繼續航拍',
        scanRightNow: '立即掃碼',
        rescanQrcode: '重新掃碼',
        noStream: '無畫面請點我',
        confirmModification: '確認更改',
    },
}