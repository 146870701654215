export default {
    tabBar: {
        masterMap: '大师地图',
        memory: '回忆',
        profile: '我的',
        order: '订单',
        noDataPopup: {
            title: '无此打卡点',
            content: '您可移至其他打卡点扫描二维码',
        },
        spotNotMatch: {
            title: '订单不适用于该打卡点',
            content: '请前往下单的打卡点扫码使用',
        },
        success: {
            title: '扫码成功',
            content: '您当前还没有可使用的订单哦~'
        },
        fail: {
            title: '扫码失败',
            content: '请重新尝试扫码或谘询客服~',
        },
        livestreamFail: {
            title: '航拍启动失败',
            content: '非常抱歉，由于本次航拍遇到阻碍无法使用。订单将会自动退款，请留意退款消息。'
        },
        notHotspotQrcode: '非有效打卡点二维码',
        exceededUsageTime: '已超过使用时间',
    },
    homePage: {
        attractionCard: {
            loginToReservation: '登录后预订航拍',
            workingTime: '营业时间',
        },
        checkinCard: {
            desc: '已开始啦~~ </br>请前往打卡点扫码使用',
            buttonText: '马上扫码'
        },
        orderPopup: {
            desc: '有1个订单即将开始',
            buttonText: '查看'
        },
        loginTipsBar: {
            desc: '立即登录大师航拍体验完成功能',
            buttonText: '去登录'
        },
        runningJourneyTipsBar: {
            desc: '您有一个大师航线正在进行中',
            buttonText: '进入'
        },
        selectFlightPage: {
            selectFlight: '选择大师航线',
            flightDesc: '包含: 3-5分钟航拍，手势互动，拍照10张，手势互动',
            selectDate: '请选择预约日期',
            selectTime: '请选择使用时间',
            reserved: '已预订',
            selected: '已选',
            soldOut: '当天售罄',
            paymentPopup: {
                desc: '请在使用时间<span style="color: #FFF; font-size: 1.4rem; font-weight: 700; margin:0 0.8rem">开始前5分钟</span>扫码打卡。</br>如超过开始时间5分钟未扫码使用视为放弃航拍。',
            },
            verifyPopup: {
                title: '活动码验证',
                desc: '由于活动数量有限，请联繫现场工作人员获取验证码，验证再预约。',
                placeholder: '输入6位活动码',
            }
        }
    },
    memoryPage: {
        title: '打卡回忆',
        checkYourRecordAfterLogin: '登录后查看您的打卡记录',
        noHotpot: '还没有打卡记录',
        video: '视频',
        photo: '照片',
        zhang: '张',
        photoProcessing: '照片处理中',
        photoProcessingError: '照片处理错误',
        photoProcessingExpired: '照片处理超時',
        unlockMore: '解锁更多',
        hotpotDetailPage: {
            title: '打卡详细',
            videoProcessing: '视频处理中',
            videoError: '视频文件出错，可尝试下拉刷新',
            videoFail: '视频剪辑失败，可尝试联系客服',
            photoProcessing: '照片处理中',
            photoError: '加载错误',
            downloadPhoto: '点击照片预览，长按保存~',
            pullrefresh: '下拉刷新',
            pullrefreshing: '正在刷新',
            easyRefresh: '松开刷新'
        },
        videoPage: {
            title: '视频下载',
            downloading: '下载中',
            click: '点击右上角',
            openInBrower: '在默认浏览器打开',
            text: '该页面<br />点击下载并保存视频'
        }
    },
    orderPage: {
        checkOrderAfterLogin: '登录后查看您的订单',
        noOrder: '当前还没有航拍订单',
        currentOrders: '当前订单',
        historicalOrders: '历史订单',
        orderId: '订单编号',
        paid: '已支付',
        fullRefund: '全额退款',
        partialRefund: '收取手续费{value}%, 退款',
        shadow: '未支付',
        orderDetailPage: {
            title: '订单详情',
            goThere: '去这里',
            paymentTime: '支付时间',
            startTime: '开始时间',
            endTime: '结束时间',
            refundDetails: '退款明细',
            paymentDetails: '支付明细',
            refundPrice: '退款金额',
            totalPrice: '总价',
            partialRefund: '本次退款需收取手续费{value}%',
            awaitingUseNote: {
                title: '待使用',
            },
            usedNote: {
                title: '已使用',
                desc: '感谢您使用，期待再次相遇'
            },
            expiredNote: {
                title: '已过期',
                desc: '已超过报到有效时限'
            },
            unfinishedNote: {
                title: '未完成',
                desc: '系统检测到有飞行危险，已中断'
            },
            cancelledNote: {
                title: '已取消',
                desc: '因天气原因无法使用，已退款'
            },
            refundsProgressNote: {
                title: '退款中',
                desc: '已提交退款申请，系统核实中'
            },
            refundFailureNote: {
                title: '退款失败',
                desc: '不符合退款条件，申请被驳回'
            },
            refundedNote: {
                title: '已退款',
                desc: '金额原路退回，请留意短信提示'
            },
            deviceErrorNote: {
                title: '未完成',
                desc: '因航拍遇到阻礙</br>支付金额將自动退款，请耐心等待'
            },
            refundApplicationPopup: {
                title: '退款申请',
                dontWanaUse: '行程有变故/不想用了',
                nonFlyable: '外部因素/天气问题/不可飞行',
                unusedOvertime: '超時未使用',
                hazardousConditions: '使用过程中遇危难情况，未完成航拍',
                explanation: '说明原因状况',
                detailsDesc: '请这裡填写原因以便我们了解情况',
                uploadPicture: '上传图片',
                parentheses: '(提高审核通过率，最多4张)',
                longText: '由于无人机飞行中会出现突发性的危险情况，需要终止航拍，本次退款将原路返还到您的支付账号上。提交申请后请留意消息通知。',
            },
            quesstionPopup: {
                title: '是否确认退款？',
                subTitle: '本次申请需要额外手续费{value}%作为商家损失费，<br />是否确定退款？',
            },
            feedbackPopup: {
                title: '提交成功',
                subTitle: '系统正在加速核实中',
                description: '审核通过后，款项将会在7-10个工作日退回',
            }
        },
        orderStatus: {
            TICKET_TYPE_UNSPECIFIED: '未知',
            TICKET_TYPE_NEW: '待使用',
            TICKET_TYPE_COMPLETED: '已使用',
            TICKET_TYPE_ABORTED: '已使用',
            TICKET_TYPE_CHECKED: '待使用',
            TICKET_TYPE_EXPIRED: '已过期',
            TICKET_TYPE_CANCELLED: '未完成',
            TICKET_TYPE_DRC_INIT_FAILED: '未完成',
            TICKET_TYPE_LIVE_STREAM_FAILED: '未完成',
            TICKET_TYPE_LIVE_STREAM_PUSHED: '进行中',
            TICKET_TYPE_BAD_WEATHER: '已取消',
            TICKET_TYPE_REFUND_REQUESTED: '退款中',
            TICKET_TYPE_REFUND_REJECTED: '退款失败',
            TICKET_TYPE_REFUND_ACCEPTED: '已退款',
            TICKET_TYPE_SHADOW: '未支付',
        }
    },
    profilePage: {
        switchLanguage: '切换语言',
        clickLogin: '点击登录',
        service: '联系客服',
        notice: '系统消息',
        setting: '设置',
        servicePopup: {
            title: '联系客服',
            desc: '长按二维码识别<br />为您提供快捷专属的谘询服务'
        },
        loginPopup: {
            content: '登录后查看详细'
        },
        noticePage: {
            title: '系统消息',
            viewDetails: '查看详情',
            successfulBooking: '预订成功',
            orderCanceled: '订单已取消',
            refundRequestSubmittedSuccessfully: '退款申请提交成功',
            refundRequestFailed: '退款申请失败',
            refundRequestApproved: '退款申请已通过',
            noNotice: '还没有消息记录',
            noticeDetailsPage: {
                title: '消息详情',
            },
            notificationType: {
                NOTIFICATION_TYPE_UNKNOWN: '未知',
                NOTIFICATION_TYPE_RESERVE_SESSION_FAILED: '预约失败',
                NOTIFICATION_TYPE_RESERVE_SESSION_SUCCESS: '预约成功',
                NOTIFICATION_TYPE_ORDER_PAYMENT_FAILED: '支付失败',
                NOTIFICATION_TYPE_ORDER_PAYMENT_SUCCESS: '支付成功',
                NOTIFICATION_TYPE_ORDER_UPCOMING: '订单即将开始',
                NOTIFICATION_TYPE_ORDER_USED: '订单已使用',
                NOTIFICATION_TYPE_ORDER_ABORTED: '用户终止订单',
                NOTIFICATION_TYPE_ORDER_CANNCELLED: '系统终止订单',
                NOTIFICATION_TYPE_ORDER_REFUND_REJECTED: '退款失败',
                NOTIFICATION_TYPE_ORDER_REFUND_APPROVED: '退款成功',
                NOTIFICATION_TYPE_ORDER_REFUND_ACCEPTED: '已申请退款',
                NOTIFICATION_TYPE_ORDER_REFUND_REQUESTED: '已申请退款',
                NOTIFICATION_TYPE_ORDER_REFUND_RETURNED: '退款成功',
            }
        },
        switchLanguagePage: {
            title: '切换语言',
            switchComplete: '切换完成',
        },
        settingPage: {
            title: '设置',
            account: '账号',
            about: '关于',
            changePhoneNumber: '更改手机号',
            serviceAgreement: '用户协议',
            privacyPolicy: '隐私政策',
            AboutMss: '关于大师航拍',
            writeoffAccount: '注销账号',
            popup: {
                title: '确定注销账号？',
                subTitle: '注销成功后，你讲无法登录或以任何方式使用当前账号，且后续无法恢复，请关注以下重要提醒：',
                deregisteredSuccessfully: '注销成功',
                contenth3: '本次注销具体包括如下信息',
                contentp1: '1.注销账号后以下信息将依照相关法律规定及协议约定，清空且无法恢复，包括个人信息、大师航拍账号信息、订单信息、航拍视频/照片等。',
                contentp2: '2.在使用大师航拍服务中产生的交易金额，将不给予退还。',
            }
        },
        serviceAgreementPage: {
            title: '用户协议',
        },
        privacyPolicyPage: {
            title: '隐私政策',
        },
        aboutMssPage: {
            title: '关于大师航拍',
            desc: '《旅游景点共享无人机系统-大师航拍》：<br />是专为旅游爱好者提供的全自动共享无人机服务。',
            slogan: '让世界看见中国的美',
        },
        changePhoneNumberPage: {
            title: '更改手机号',
            newPhoneNumber: '请输入新手机号码',
            verifyCurrentPhone: '验证当前手机并解绑',
            originalPhoneNumber: '请输入原手机号码',
            modifyPhoneBinding: '绑定新手机号',
            modifyPhoneBindingSuccess: '绑定新手机号成功',
            tips: '点击下一步即爲解绑当前手机号',
            successToast: {
                title: '更改成功',
            },
            errorToast1: {
                title: '手机号已被使用',
            },
            errorToast2: {
                title: '短信服务错误',
            }
        }
    },
    loginPage: {
        loginByWechat: '微信一键登录',
        noLogin: "暂不登录",
        weChatAuthorizedLogin: '微信授权登录',
        tipsText: '我已阅读并同意',
        userAgreement: '《用户协议》',
        privacyPolicy: '《隐私政策》',
        popup: {
            title: '协议/政策',
            content: '为了更好的保障您的合法权益，请您阅读并同意以下协议<span style="color: #016BFF">《用户协议》《隐私政策》</span>'
        }
    },
    bindingPhonePage: {
        tips: '首次登录需要绑定您的手机号',
        phone: '请输入手机号',
        verificationCode: '请输入短信验证码',
        getCode: '获取验证码',
        getCodeAgain: '重新获取',
        bindingPhone: '绑定手机',
        phoneError: '请输入正确位数的手机号',
        unbindPhoneError: '该号码与绑定号码不一致，请重新输入',
        verificationCodeError: '验证码错误，请重新输入',
        areaCode: {
            mainLand: '中国大陆',
            hk: '中国香港',
            mo: '中国澳门',
            tw: '中国台湾',
        },
        successToast: {
            title: '绑定成功',
        },
        toast1: {
            title: '手机号已被绑定',
            content: '请更换手机号再尝试'
        },
        toast2: {
            title: '短信验证码错误',
            content: '请重新获取验证码'
        }
    },
    wechatPage: {
        title: '请使用微信访问<span style="color: #42BDFF">大师航拍</span>',
        desc1: '用微信“扫一扫”，现场扫描打卡点上的二维码即可进入大师航拍官网。',
        desc2: '在当前页面截图发送到微信聊天框，长按识别二维码添加公众号，在公众号进入官网。',
    },
    tutorialsPage: {
        step1: {
            title: '航拍站位',
            description: '注意观测现场指示</br>请站在地上贴有标志点的最佳航拍位置上等待拍摄。'
        },
        step2: {
            title: '拍照操作',
            description1: '滑动操控干可控制摄影角度，调整画面位置。',
            description2: '上下拖动滚轴可放大/缩小，控制画面变焦。',
            description3: '大师角度',
            description4: '点击回到系统设置好的最美镜头位置。',
            description5: '即将拍照',
            description6: '拍照',
            description7: '点拍照后会进入3秒面延时拍照',
        },
        step3: {
            title: '实时播报',
            description: '无人机飞行中会採用语音和文字结合方式提示，请留意手机音量。'
        },
        step4: {
            title: '中断航拍',
            description1: '点击“中断”按钮退出本次航拍，视为航拍结束。',
            description2: '当前拍摄记录会保存在回忆',
            description3: '如航拍中，无人机因外部因素遇到飞行危险，系统会自动中断结束本次航拍，可到订单申请退款。',
        },
        tutorialsPopup: {
            title: '航拍准备开始啦~',
            subTitle: '为保证良好的航拍体验</br>请确保已站在脚印范围',
            buttonText: '我已在脚印范围内',
        },
        guideline: '指引',
        yourReservation: '您的预订航拍',
        started: '已开始',
        asaPossible: '请尽快启动航拍',
        yourReservationStill: '您预订的航拍还有',
        keepInMind: '开始，请了解以下注意事项'
    },
    aerialPage: {
        step1: {
            name: '起飞'
        },
        step2: {
            name: '自拍'
        },
        step3: {
            name: '航拍'
        },
        shotTips: '即将拍照',
        flightPoints: '航线节点',
        flightInitTips: '无人机启动中',
        flightFinishPopup: {
            statusText: '您已完成本次航拍之旅',
            subTitle: '预计<span style="color: #FFF; font-size: 1.8rem; font-weight: 700; line-height: 2.6rem; margin:0 0.8rem">30分钟后</span>图片视频会处理完成<br />并记录在《回忆》中，请耐心等待~',
        },
        autoTakePhotoText: '准备进入<br />自动拍照啦~',
        stopFlightPopup: {
            title: '是否强制中断航拍？',
            subTitle: '强制中断航拍将视为完成本次航拍，当前拍摄<br />内容会保存到《回忆》中。',
            content: '强制停止航拍将不能申请退款'
        },
        terminateFlightPopup: {
            title: '航拍终止提示',
            subTitle: '抱歉，由于无人机飞行中遇到危险情况，系统将<br />终止本次飞行任务。',
            content: '您可到订单详情中申请退款'
        },
        liveStreamFailedPopup: {
            title: '无人机直播连接中断',
            subTitle: '连接无人机直播画面时出错，请尝试重新扫描二维码。',
        },
        flightDescription: {
            default: '无人机启动中，请耐心等待~',
            d0: '无人机正在从机场飞出',
            d1: '就位拍照',
            d2: '就位拍摄',
        },
        loadingToast: {
            photo: '已进入自拍',
            recording: '已进入航拍',
            exit: '航拍结束，正在退出',
        }
    },
    common: {
        hotpot: '打卡点',
        copy: '复制',
        copySuccess: '复制成功',
        copyFailed: '复制失败',
        verifySuccess: '验证成功',
        verifyFailed: '验证失败',
        useDate: '使用日期',
        useTime: '使用时间',
        date: '日期',
        time: '时间',
        noMore: '沒有更多啦~',
        purchaseNotes: {
            title: '购买须知',
            note1: '“使用时间”的前5分钟即可提前使用航拍；',
            note2: '需到现场打卡点扫描二维码使用；',
            note3: '超过“使用时间”5分钟后，不能再使用航拍 ，且不可退改，请确认好时间后再行购买。',
        },
        refundRules: {
            title: '退款规则',
            refundRequestTimeToUse: '申请退款距使用时间',
            unusedOvertime: '超时未使用',
            otherReason: '外部因素/天气因素/不可飞行',
            handlingFee: '手续费',
            verification: '核实通过'
        },
        termsOfUse: {
            title: '使用须知',
            howToUse: {
                title: '如何使用',
                description: '前往打卡点现场，扫描二维码即可使用航拍。'
            },
            timeOfUse: {
                title: '使用时间',
                description: '使用时间前5分钟到后5分钟，例如8:15，8:10 - 8:20可使用。'
            },
            timeoutUnused: {
                title: '超时未使用',
                description: '超过使用时间5分钟后将不可使用，视为放弃本次航拍。'
            },
        },
        puzzleVcode: {
            desc: '向右滑动完成验证',
        },
        servicePopup: {
            title: '微信客服',
            content: '长按二维码识别</br>为您提供快捷专属的谘询服务'
        },
        hour: '小时',
        minute: '分钟',
        more: '更多',
        paymentSuccessful: '支付成功',
        sessionNotAvailable: '当前时间已被预订',
    },
    buttonText: {
        confirm: '确定',
        cancel: '取消',
        nextStep: '下一步',
        reservation: '预订航拍',
        viewOrder: '查看订单',
        aerialPhotographyGuide: '航拍指引',
        agreeAndLogin: '同意并登录',
        toLogin: '去登录',
        reserveNow: '马上预订',
        downloadVideo: '下载视频',
        gotIt: '我知道了',
        requestRefund: '申请退款',
        contactCustomerService: '联系客服',
        submitApplication: '提交申请',
        logout: '退出登录',
        confirmSwitching: '确定切换',
        completionBonding: '完成绑定',
        agreeAndBind: '同意并绑定',
        login: '登录',
        notApply: '不申请了',
        identifyAndApply: '确定并申请',
        thinkAbout: '我再想想',
        definitiveCancellation: '确定注销',
        startupAerial: '启动航拍',
        viewMemory: '查看回忆',
        breakAerial: '中断航拍',
        continueAerial: '继续航拍',
        scanRightNow: '立即扫码',
        rescanQrcode: '重新扫码',
        noStream: '无画面请点我',
        confirmModification: '确定更改',
    }
}